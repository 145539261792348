import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import ProductsByCategory from '../components/productsByCategory'
import ProductsByAZ from '../components/productsByAZ'
import get from 'lodash/get'

class TabsDefault extends React.Component {
  state = {
    activeItem: '1',
  }

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      })
    }
  }

  render() {
    const az = get(this, 'props.data.az.edges')
    const categories = get(this, 'props.data.categories.edges')
    const heroImage = get(this, 'props.data.heroImage')
    return (
      <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={heroImage.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt=""
          title="Products"
          subtitle="Manage critical wireless infrastructures reliably and securely."
          ctatext="Let's talk"
          ctaslug="/contact/"          
          type="root"
        />
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <p className="font-w-400 text-xs-extra-large divlink text-opening-para">Firetide's product portfolio enables building high capacity wireless networks that are comparable in performance and reliability to fiber.</p>
                <p className="text-medium mt-3">                    
                  The biggest advantage of Firetide over its competitors is its advanced wireless technology that powers all its product – AutoMesh™. 
                  The benefits of an AutoMesh powered wireless network include:<br /><br />
                  <ul>
                    <li>Ability to backhaul multi applications over a single network namely video, voice and data</li>
                    <li>Superior performance (high throughput, reliability and low latency) than other wireless technologies</li>
                    <li>Superior scalability without any degradation in performance</li>
                    <li>Lower cost of ownership and rapid deployment compared to fiber</li>
                    <li>Mobility at high speeds while maintaining Fiber like performance</li>
                  </ul>
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBNav className="nav-tabs mt-5 mb-0">
                  <MDBNavItem className="nav-item-border">
                    <a
                      className={
                        this.state.activeItem === '1'
                          ? 'nav-link active text-xs-small text-medium-small'
                          : 'nav-link text-xs-small text-medium-small'
                      }
                      onClick={this.toggle('1')}
                      role="tab"
                    >
                      A-Z
                    </a>
                  </MDBNavItem>
                  <MDBNavItem className="nav-item-border">
                    <a
                      className={
                        this.state.activeItem === '3'
                          ? 'nav-link active text-xs-small text-medium-small'
                          : 'nav-link text-xs-small text-medium-small'
                      }
                      onClick={this.toggle('3')}
                      role="tab"
                    >
                      Product categories
                    </a>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={this.state.activeItem}>
                  <MDBTabPane tabId="1" role="tabpanel">
                    <div className="intro-content">
                      <ProductsByAZ data={az} />
                    </div>
                  </MDBTabPane>
                  <MDBTabPane tabId="3" role="tabpanel">
                    <div className="intro-content">
                      <ProductsByCategory data={categories} />
                    </div>
                  </MDBTabPane>
                </MDBTabContent>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </Layout>
      </div>      
    )
  }
}
export default TabsDefault

export function Head({data, location }) {
  const heroImage = data.heroImage
  const post = data.markdownRemark
  return (
    <SEO
      title="Products by A-Z and product capabilities"
      description="Firetide products listed out alphabetically (A-Z) and also by product capabilities that specific products are designed to provide"
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}  
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    az: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___title }
      filter: { frontmatter: { template: { eq: "products" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
          }
          fields {
            slug
          }
        }
      }
    }
    categories: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___categoryname }
      filter: { frontmatter: { categoryname: { ne: null } } }
    ) {
      edges {
        node {
          frontmatter {
            categoryname
            categorydescription
            related {
              link
              name
            }
          }
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
        }
      }
    }
`
